<template>
  <!-- 本人確認書類提出 -->
  <div>
    <b-card no-body class="py-4">
      <b-card-body>
        <div v-if="!email">
          <!-- TRUSTDOCKアプリ起動 -->
          <div class="text-center">
            <b-icon
              icon="card-heading"
              scale="3"
              variant="cmain"
              class="mb-5"
            ></b-icon>
          </div>
          <b-card-text class="lead text-center text-cmain">
            続いてTRUSTDOCKアプリを起動して本人確認書類の提出を行います。
          </b-card-text>
          <b-form-row>
            <b-col offset="3" cols="6" offset-sm="4" sm="4">
              <b-button variant="csub" class="w-100 opener">
                アプリ起動
              </b-button>
            </b-col>
          </b-form-row>
          <b-card-text v-if="message" class="mt-3 text-center text-caccent">
            {{ message }}
          </b-card-text>
        </div>
        <div v-else>
          <!-- 提出完了/メール送信後表示 -->
          <div class="text-center">
            <b-icon
              icon="envelope"
              scale="3"
              variant="cmain"
              class="mb-5"
            ></b-icon>
          </div>
          <b-card-text class="lead text-center text-cmain">
            仮申請ありがとうございます。<br />メールをご確認ください。
          </b-card-text>
          <b-card-text>
            <b class="text-caccent"
              >まだMYPLR口座開設申請は完了していません。</b
            >
            ご入力頂いたメールアドレスに、申請完了のためのメールをお送りいたします。送信されたメールに記載されているURLを24時間以内にクリックして申請を完了させてください。
          </b-card-text>
          <b-card-text class="text-csub">
            メールが届かない場合は、ご入力頂いたメールアドレスが間違っている、迷惑メールフォルダに入っているなどが考えられます。一度ご確認いただき、間違っている場合は、お手数ですが再度申請をお願いします。その他の場合は、管理者にご連絡ください。<br />
            ご入力頂いたメールアドレス: {{ email }}
          </b-card-text>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  props: ['applicationId', 'publicId', 'planId'],
  data() {
    return {
      message: null,
      trustdock: null,
      email: null,
    }
  },
  mounted() {
    console.log('** create trustdock instance **')
    this.trustdock = new window.Trustdock({
      publicId: this.publicId,
      plans: [
        {
          id: this.planId,
        },
      ],
      openerSelector: '.opener',
    })
    const loadedAction = () => {
      console.log('** loaded **')
    }
    const submittedAction = () => {
      console.log('** submitted **')
      this.sendEmail()
    }
    const completedAction = () => {
      console.log('** completed **')
    }
    const canceledAction = () => {
      console.log('** canceled **')
      this.message =
        '書類を提出せずにアプリが閉じられました。再度アプリを起動して提出を行なってください。'
    }

    this.trustdock.on('loaded', loadedAction)
    this.trustdock.on('documentsubmitted', submittedAction)
    this.trustdock.on('completed', completedAction)
    this.trustdock.on('canceled', canceledAction)
  },
  destroyed() {
    console.log('** destroyed **')
    this.trustdock.remove()
  },
  methods: {
    sendEmail() {
      // アドレス確認用メール送信
      this.message = '確認メール送信中。しばらくお待ちください。'
      this.$axios
        .post(
          '/account_opening/application_ekyc/' +
            this.applicationId +
            '/send_email'
        )
        .then((response) => {
          console.log(response)
          this.email = response.data.email
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            console.log(error.response)
            this.message =
              'メール送信エラー: ' +
              error.response.data.detail +
              ' 管理者へご連絡ください。'
          } else {
            this.message = 'メール送信に失敗しました。管理者へご連絡ください。'
          }
        })
    },
  },
}
</script>
